<template>
    <div>
        <div>
            <h2 class="th-title">Daftar Vendors</h2>
            <!-- <h2 class="th-title">Monitoring Tanggal {{ getHumanDate(monitoringDate) }}</h2> -->
            <!-- <a>
          <p class="text-muted mb-5" @click="showModalDate"> Ganti tanggal monitoring</p>
        </a> -->
            <!-- <a-modal v-model:visible="visibleDate" title="Basic Modal" @ok="handleDate">
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </a-modal> -->
        </div>
        <a-card>
            <div class="d-flex justify-content-between">
                <a-input-search placeholder="Cari Nama Vendor..." v-model:value="search" style="width: 250px;"
                    class="mb-3" />
                <router-link :to="`/vendors/create`">
                    <a-button>
                        <PlusOutlined />New Vendors
                    </a-button>
                </router-link>
            </div>
            <div>
                <a-table :columns="columns" :data-source="data" bordered>
                    <template #name="{ text, record }">
                        <router-link :to="{ path: `/vendors/${record.id}` }" class="vb__utils__link">
                            {{ text }}
                        </router-link>
                    </template>
                    <template #action="{ record }">
                        <WarningFilled :style="{ color: 'firebrick' }"> </WarningFilled> &nbsp; Monitoring belum dilakukan
                        <span v-if="record.key === idEdit">
                        </span>
                    </template>
                </a-table>
            </div>
        </a-card>
    </div>
</template>
  
<script>
import { onMounted, reactive, ref, toRaw, watch } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, WarningFilled } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment'
import {
    generateTask, getTaskByDate,
} from '../../services/axios/modc/eos'
import {
    getVendors,
} from '../../services/axios/modc/vendor'
const columns = [
    {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Nama Vendor',
        dataIndex: 'name',
        key: 'name',
        slots: { customRender: 'name' },
    },
    {
        title: 'Vendor Sign',
        dataIndex: 'vendorSign',
        key: 'name',
        // slots: { customRender: 'name' },
    },
    {
        title: 'Vendor Email',
        dataIndex: 'email',
        key: 'category',
        // slots: { customRender: 'description' },
    },
    {
        title: 'PIC',
        dataIndex: 'PIC',
        key: 'subCategory',
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'subCategory',
    },
    // {
    //     title: 'Keterangan',
    //     key: 'ceklis',
    //     slots: { customRender: 'action' },
    //     align: 'center',
    // },
];

export default {
    components: {
        WarningFilled,
        PlusOutlined,
    },

    setup() {
        const store = useStore()
        let data = ref([])
        let search = ref(null)
        let modalAddVisible = ref(false)
        let formAdd = reactive({
            name: null,
            description: null,
        })
        let formEdit = ref({
            name: null,
            description: null,
        })
        let idEdit = ref(null)
        const loadingAdd = ref(false)
        const rulesRef = reactive({
            name: [
                {
                    required: true,
                    message: 'Nama harus diisi!',
                    trigger: 'change',
                },
            ],
        })

        onMounted(() => {
            fetchCategory()
        })

        const fetchCategory = async (search = '') => {
            const payload = {
                eosId: 1,
                date: moment(monitoringDate.value).format('YYYY-MM-DD'),
            }
            const { vendor } = await getVendors(search)
            vendor.forEach((e, idx) => {
                e.key = idx + 1
            })
            console.log(vendor)
            data.value = vendor
        }

        const showModalAdd = () => {
            modalAddVisible.value = true
        }

        const handleOk = () => {
            modalAddVisible.value = false
        }

        const closeEdit = () => idEdit.value = null

        const { validate: validateEdit, validateInfos: validateInfosEdit } = useForm(formEdit, rulesRef);
        const confirmEdit = async (record) => {
            try {
                const payload = {
                    id: record.key,
                    data: {
                        name: formEdit.value.name,
                        description: formEdit.value.description,
                    },
                }
                const tes = await validateEdit()
                // const tes2 = await validate()
                Modal.confirm({
                    title: `Apa anda yakin ingin mengubah data pada kategori '${record.name}'?`,
                    icon: createVNode(ExclamationCircleOutlined),
                    async onOk() {
                        try {
                            const data = await store.dispatch('category/UPDATE_CATEGORY', payload)
                            modalAddVisible.value = false
                            fetchCategory()
                            notification.success({
                                message: `Berhasil Mengubah Data Pada Kategori '${record.name}'`,
                            })
                            idEdit.value = null
                        } catch (err) {
                            console.log(err)
                        }
                    },
                    onCancel() { },
                })
            } catch (err) {
                console.log(err)
            }
        }

        const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);

        const confirmAdd = async () => {
            try {
                await validate()
                // console.log(console.log(toRaw(formAddRef)))
                Modal.confirm({
                    title: 'Apa anda yakin ingin menambahkan kategori?',
                    icon: createVNode(ExclamationCircleOutlined),
                    async onOk() {
                        try {
                            const data = await store.dispatch('category/ADD_CATEGORY', { ...formAdd })
                            modalAddVisible.value = false
                            fetchCategory()
                            notification.success({
                                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Kategori`,
                            })
                            resetFields()
                        } catch (err) {
                            console.log(err)
                        }
                    },
                    onCancel() { },
                })
            } catch (err) { }
        }

        const categoryAction = async (record, action) => {
            const data = {
                id: record.key,
                data: {
                    name: record.name,
                    description: record.description,
                },
            }
            if (action === 'DELETE') {
                Modal.confirm({
                    title: `Apa anda yakin ingin menghapus kategori '${record.name}' dari daftar?`,
                    icon: createVNode(ExclamationCircleOutlined),
                    async onOk() {
                        try {
                            const res = await store.dispatch('category/DELETE_CATEGORY', { ...data })
                            fetchCategory()
                            notification.success({
                                message: `Berhasil Menghapus '${record.name}' Dari Daftar Kategori`,
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    },
                    onCancel() { },
                })
            } else if (action === 'UPDATE') {
                idEdit.value = record.key
                formEdit.value = {
                    name: record.name,
                    description: record.description || null,
                }
                // const data = await store.dispatch(action + '_CATEGORY', { id, data })
            }
        }

        const handleSearchCategory = () => {
            fetchCategory(search.value)
        }

        const visibleDate = ref(false);
        const showModalDate = () => {
            visibleDate.value = true;
        };
        const handleDate = e => {
            visible.value = false;
        };

        const monitoringDate = ref()
        watch([monitoringDate, search], (newValue, oldValue) => {
            // Fungsi ini akan berjalan setiap kali myVariable berubah
            // Panggil fungsi atau lakukan tindakan lain yang Anda inginkan di sini
            fetchCategory(search.value);
        });

        const generateMonitoringTask = async () => {
            const payload = {
                eosId: 1,
                customerId: 1,
                locationId: 1,
                date: moment(monitoringDate.value).format('YYYY-MM-DD'),
            }
            const data = await generateTask(payload)
            fetchCategory()

        }

        return {
            monitoringDate,
            visibleDate,
            showModalDate,
            handleDate,
            data,
            columns,
            search,
            modalAddVisible,
            showModalAdd,
            handleOk,
            formAdd,
            rulesRef,
            loadingAdd,
            confirmAdd,
            validate,
            validateInfos,
            validateEdit,
            validateInfosEdit,
            categoryAction,
            idEdit,
            formEdit,
            closeEdit,
            confirmEdit,
            handleSearchCategory,
            generateMonitoringTask,

        }
    },

    methods: {
        getHumanDate: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
}
</script>
  